<template>
  <div class="trust-project__cards container-cards">
    <div class="card" v-for="item in items" v-bind:key="item">
      <div class="card__num">{{ item.number }}</div>
      <div class="card__title">{{ item.title }}</div>
      <div class="card__text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardTrust",
  props: {
    cardsData: Object
  },
  data() {
    return {
      items: this.cardsData
    }
  }
}
</script>

<style scoped>

</style>