<template>
  <AppHeader />
  <main>
    <router-view />
    <FormMini />
  </main>
  <AppFooter />
</template>

<style lang="scss">
@import "scss/style";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<script>
import AppHeader from "@/layout/Header";
import AppFooter from "@/layout/Footer";
import FormMini from "@/components/FormMini";
export default {
  components: {FormMini, AppFooter, AppHeader},
}
</script>