<template>
  <div class="modal__order-service">
    <form class="form__order-service">
      <span class="close-modal" v-on:click="closeModal">
        <svg height="512px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="512px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>
      </span>
      <span class="form__title">
        Заказать услугу
      </span>
      <div class="input-group">
        <span class="input-group__title">Тип услуги</span>
        <select v-model="type_service" name="type_service" >
          <option value="1">Веб-разработка</option>
          <option value="2">Мобильная разработка</option>
        </select>
      </div>
      <div class="input-group">
        <span class="input-group__title">Наименование услуги</span>
        <input type="text" v-model="name_service" name="name_service" placeholder="Наименование услуги">
      </div>
      <div class="input-group">
        <span class="input-group__title">Организация</span>
        <input type="text" v-model="name_organization" name="name_organization" placeholder="Наименование организации">
      </div>
      <div class="input-group">
        <span class="input-group__title">Телефон</span>
        <input type="text" maxlength="13" v-model="phone" name="phone" placeholder="+375295609012">
      </div>
      <div class="input-group">
        <span class="input-group__title">Почта</span>
        <input type="email" maxlength="35" v-model="email" name="email" placeholder="poludenmilena@gmail.com">
      </div>
      <div class="input-group">
        <span class="input-group__title">Дополнительные условия</span>
        <textarea type="text" v-model="other_text" name="other_text" placeholder="Дополнительные условия"></textarea>
      </div>
      <span class="validation-text">

      </span>
      <button class="button button-request" v-on:click="sendService" type="button">Отправить</button>
    </form>
  </div>
</template>

<script>
import axios from "axios"
import variables from "@/utils/variables";

export default {
  name: "FormMini",
  props: {
    activeFormMini: Boolean
  },
  data() {
    return {
      type_service: 1,
      name_service: '',
      name_organization: '',
      phone: '',
      email: '',
      other_text: ''
    }
  },
  methods: {
    closeModal() {
      document.querySelector('.modal__order-service').classList.remove('active')
    },
    sendService() {
      let validateBlock = document.querySelector('.validation-text');
      let htmlView = '';

      validateBlock.classList.remove('red');
      validateBlock.classList.remove('green');

      axios.post(`${variables.API_URL}/api/order`, {
        type_service: this.type_service,
        name_service: this.name_service,
        name_organization: this.name_organization,
        phone: this.phone,
        email: this.email,
        other_text: this.other_text
      }).then(response => {
        if (response.data.error === false) {
          validateBlock.textContent = response.data.message
          validateBlock.classList.add('green');
        } else if(response.data.success === false) {
          let arrayResponse = Object.values(response.data.message)
          arrayResponse.forEach(item => {
            htmlView += `<span>${item[0]}</span>`
          })

          validateBlock.innerHTML = htmlView;
          validateBlock.classList.add('red');
        }
      })
    }
  }
}
</script>

<style scoped>

</style>