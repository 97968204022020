<template>
  <div class="header" >
    <div class="content-wrapper">
      <img alt='decoration' class="header__decoration" src="../assets/images/decorations/header_decoration.svg" title="Узор">
        <router-link class="logo" :to="{ name: 'main' }">
          <img src="../assets/images/logo.svg" alt="Jarovit" title="Логотип">
        </router-link>
        <div class="header__group-buttons" v-bind:class="{ active: activeMenu }">
          <div class="nav-block">
            <router-link class="nav-item" :to="{ name: 'about' }" v-on:click="activeMenu = !activeMenu"><span>О нас</span></router-link>
            <router-link class="nav-item" :to="{ name: 'services' }" v-on:click="activeMenu = !activeMenu"><span>Услуги</span></router-link>
            <router-link class="nav-item" :to="{ name: 'portfolio' }" v-on:click="activeMenu = !activeMenu"><span>Портфолио</span></router-link>
            <router-link class="nav-item" :to="{ name: 'team' }" v-on:click="activeMenu = !activeMenu"><span>Команда</span></router-link>
            <router-link class="nav-item" :to="{ name: 'reviews' }" v-on:click="activeMenu = !activeMenu"><span>Отзывы</span></router-link>
            <router-link class="nav-item" :to="{ name: 'calculator' }" v-on:click="activeMenu = !activeMenu"><span>Калькулятор услуг</span></router-link>
            <div class="nav-item button__header-order-service" v-on:click="activeModal"><span>Заказать услугу</span></div>
          </div>
          <div class="button header__button" v-on:click="activeModal"><span>Заказать услугу</span></div>
        </div>
      <div class="header-ic hamburger tablet" v-on:click="activeMenu = !activeMenu" v-bind:class="{ active: activeMenu }">
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      activeMenu: false
    }
  },
  methods: {
    activeModal() {
      document.querySelector('.modal__order-service').classList.toggle('active')
    }
  }
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>