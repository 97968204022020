<template>
  <div class="text-image__content" v-for="item in items" v-bind:key="item">
    <div class="text">
      <div class="title">{{ item.title }}</div>
      <div class="portfolio-text">{{ item.text }}</div>
      <a :href="item.url" class="text-button">Подробнее</a>
    </div>
    <div class="text-image__image image">
      <img alt="text-image_picture" :src="item.image_main">
    </div>
  </div>
</template>

<script>
export default {
  name: "ElementTextButtonImage",
  props: {
    elementData: Object
  },
  data() {
    return {
      items: this.elementData
    }
  }
}
</script>

<style scoped>

</style>