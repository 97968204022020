<template>
  <section class="page-header light-block animation">
    <div class="content-wrapper">
      <div class="page-header__content-block">
        <div class="text-title">Разработка Frond-End и Back-End части вашего сайта</div>
        <div class="text">
          Работайте без посредников и переплат напрямую с командой исполнителей!
        </div>
        <router-link class="button page-header__button" :to="{ name: 'about' }" v-on:click="activeMenu = !activeMenu"><span>Подробнее</span></router-link>
      </div>
      <div class="page-header__image-block image">
        <img alt="devices" src="../assets/images/background/devices.png" title="Устройства">
      </div>
    </div>
  </section>
  <!--Блок с доверием-->
  <section class="trust-project dark-block">
    <div class="content-wrapper">
      <div class="title-block">Почему вы можете доверить нам ваш проект?</div>
      <CardTrust v-bind:cards-data="cardsTrust" />
    </div>
  </section>
  <!--Блок о нас-->
  <section class="about light-block" id="about">
    <img alt='decoration' class="about__decoration" src="../assets/images/decorations/about_decoration.svg" title="Узор">
    <div class="content-wrapper">
      <div class="title-block">О нас</div>
      <ElementTextImage v-bind:element-data="textAbout" />
    </div>
  </section>
  <!--Блок с преимуществом-->
  <section class="advantages dark-block">
    <div class="content-wrapper">
      <div class="title-block">Преимущества</div>
      <CardAdvantage v-bind:cards-data="cardsAdvantage" />
    </div>
  </section>
  <!--Блок с услугами-->
  <section class="services light-block" id="services">
    <img alt='decoration' class="service__decoration" src="../assets/images/decorations/services_decoration.svg"
         title="Узор">
    <div class="content-wrapper">
      <div class="title-block">Услуги</div>
      <CardService v-bind:cards-data="cardsService" />
      <router-link :to="{ name: 'services' }" v-on:click="activeMenu = !activeMenu" class="button services__button-bottom"><span>Показать все</span></router-link>
    </div>
  </section>
  <!--Блок с портфолио-->
  <section class="about portfolio-page dark-block">
    <img alt='decoration' class="about__decoration" src="../assets/images/decorations/about_decoration.svg" title="Узор">
    <div class="content-wrapper">
      <div class="title-block">Портфолио</div>
      <ElementTextButtonImage  v-bind:element-data="portfolioItems" />
    </div>
    <router-link :to="{ name: 'portfolio' }" v-on:click="activeMenu = !activeMenu" class="button services__button-bottom"><span>Показать все</span></router-link>
  </section>
  <!--Блок с партнерами (Нужна доработка логики слайдера)-->
  <section class="partners light-block">
    <div class="content-wrapper">
      <div class="title-block">Партнеры</div>
      <div class="group-buttons">
        <img alt='decoration' class="partners__decoration" src="../assets/images/decorations/partners_decoration.svg"
             title="Узор">
        <div class="arrow-button arrow-left partners__button-prev" @click="prevPartnersSlider">
          <img alt="arrow-left arrow-left" src="../assets/images/icons/arrow_left.svg" title="Назад">
        </div>
        <div class="arrow-button arrow-right partners__button-next" @click="nextPartnersSlider">
          <img alt="arrow-right" src="../assets/images/icons/arrow_right.svg" title="Вперед">
        </div>
      </div>
      <div class="partners__container-brands">
        <Carousel :settings="settings" :breakpoints="breakpoints" ref="PartnersSlider">
          <Slide v-for="slide in imagesSlider" v-bind:key="slide">
            <div class="">
              <img alt="" :src=" require('../assets/images/' + slide.image) ">
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </section>
  <!--Блок с командой-->
  <section class="team dark-block" id="team">
    <div class="content-wrapper">
      <div class="title-block">Наша команда</div>
      <div class="team__container">
        <div class="image-block">
          <img alt="video" src="../assets/images/background/team_video.png" title="Видео">
          <a class="play-button" target="_blank" href="https://www.youtube.com/watch?v=JeFsxvhmnpw">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#E8DA93"/>
              <path d="M30 0C13.4317 0 0 13.4314 0 30C0 46.5686 13.4317 60 30 60C46.5683 60 60 46.5686 60 30C60 13.4314 46.5683 0 30 0ZM40.3688 31.5902L25.3687 40.9652C25.0652 41.1547 24.7201 41.25 24.375 41.25C24.0623 41.25 23.7492 41.1722 23.4659 41.0147C22.8698 40.6842 22.5 40.057 22.5 39.375V20.625C22.5 19.943 22.8698 19.3158 23.4659 18.9853C24.0619 18.653 24.7907 18.6731 25.3687 19.0348L40.3688 28.4098C40.9167 28.7531 41.25 29.3537 41.25 30C41.25 30.6463 40.9167 31.247 40.3688 31.5902Z" fill="white"/>
            </svg>
          </a>
        </div>
        <div class="content-block">
          <p class="text">
            Мы вынуждены отталкиваться от того, что синтетическое тестирование позволяет
            оценить значение глубокомысленных рассуждений. Принимая во внимание показатели успешности,
            консультация с широким активом, в своём классическом представлении, допускает внедрение
            существующих финансовых и административных условий. Прежде всего, синтетическое тестирование
            выявляет срочную потребность первоочередных требований! Укрепление и развитие внутренней
            структуры требует анализа как самодостаточных, так и внешне зависимых концептуальных решений.
            Каждый из нас понимает очевидную вещь: глубокий уровень погружения предполагает независимые
            способы реализации поэтапного и последовательного развития общества.
          </p>
          <router-link :to="{ name: 'team' }" v-on:click="activeMenu = !activeMenu" class="button team__button"><span>Подробнее</span></router-link>
        </div>
      </div>
    </div>
  </section>

  <!--Блок с отзывами-->
  <section class="reviews dark-block" id="reviews">
    <div class="content-wrapper">
      <img alt='decoration' class="reviews__decoration" src="../assets/images/decorations/review_decoration.svg"
           title="Узор">
      <div class="group-buttons">
        <div class="arrow-button">
          <img alt="arrow-left arrow-left" src="../assets/images/icons/arrow_left.svg" title="Назад">
        </div>
        <div class="arrow-button arrow-right">
          <img alt="arrow-right" src="../assets/images/icons/arrow_right.svg" title="Вперед">
        </div>
      </div>
      <div class="title-block">Отзывы</div>
      <CardReview v-bind:cards-data="cardsReviews" />
    </div>
  </section>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import CardTrust from "@/components/cards/CardTrust";
import CardAdvantage from "@/components/cards/CardAdvantage";
import CardService from "@/components/cards/CardService";
// import CardTypes from "@/components/cards/CardTypes";
import CardReview from "@/components/cards/CardReview";
import ElementTextButtonImage from "@/components/ElementTextButtonImage";
import ElementTextImage from "@/components/ElementTextImage";
import axios from "axios"
import variables from "@/utils/variables";
export default {
  name: "MainView",
  components: { ElementTextImage, ElementTextButtonImage, CardReview, CardService, CardAdvantage, CardTrust, Carousel, Slide},
  data() {
   return {
     cardsTrust: [
       {
         number: '01',
         title: 'Моментальная доставка на email',
         text: 'В своём стремлении повысить качество жизни что понимание сути ресурсосберегающих технологий требует определения.'
       },
       {
         number: '02',
         title: 'Моментальная доставка на email',
         text: 'В своём стремлении повысить качество жизни что понимание сути ресурсосберегающих технологий требует определения.'
       },
       {
         number: '03',
         title: 'Моментальная доставка на email',
         text: 'В своём стремлении повысить качество жизни что понимание сути ресурсосберегающих технологий требует определения.'
       },
       {
         number: '04',
         title: 'Моментальная доставка на email',
         text: 'В своём стремлении повысить качество жизни что понимание сути ресурсосберегающих технологий требует определения.'
       },
     ],
     cardsAdvantage: [
       {
         image: 'icon_globus-rocket.svg',
         title: 'Максимальный охват стран',
       },
       {
         image: 'icon_stats.svg',
         title: 'Постоянное развитие',
       },
       {
         image: 'icon_leader.svg',
         title: 'Лидер в своем деле',
       },
       {
         image: 'icon_security.svg',
         title: 'С нами безопасно',
       }
     ],
     cardsService: [
     ],

     cardsReviews: [
     ],
     textAbout: [
       {
         image: 'about_picture.png',
         text: 'Учитывая ключевые сценарии, сложившаяся структура требует определения своевременного выполнения сверхзадачи. Разнообразный и богатый опыт говорит нам, что укрепление и развитие внутренней структуры говорит о возможностях прогресса профессионального сообщества. Современная методология разработки, а также свежий взгляд на привычные вещи - безусловно открывает новые горизонты для приоритизации разума над эмоциями.  Каждый из нас понимает очевидную вещь: дальнейшее развитие различных форм деятельности создаёт необходимость включения в производственный план целого ряда внеочередных мероприятий с учётом комплекса новых предложений. Учитывая ключевые сценарии, сложившаяся структура требует определения своевременного выполнения сверхзадачи. '
       }
     ],
     portfolioItems: [],
     imagesSlider: [
       {
         image: 'companies/opera.png'
       },
       {
         image: 'companies/tyco.png'
       },
       {
         image: 'companies/google.png'
       },
       {
         image: 'companies/jooble.png'
       },
       {
         image: 'companies/fedex.png'
       },
       {
         image: 'companies/adobe.png'
       },
       {
         image: 'companies/facebook.png'
       },
       {
         image: 'companies/ebay.png'
       },
     ],
     settings: {
       itemsToShow: 4,
       snapAlign: 'start',
       transition: 1000,
       autoplay: 4500,
       mouseDrag: false,
       touchDrag: false,
       wrapAround: true
     },
     breakpoints: {
       1280: {
         itemsToShow: 4,
         snapAlign: 'start',
       },
       810: {
         itemsToShow: 3,
       },
       300: {
         itemsToShow: 1,
       }
     }
   }
  },
  created() {
    axios.get(variables.API_URL + '/api/about').then((response) => {
      if (response.data.data.length && typeof response.data.data[0].text !== 'undefined') {
        this.textAbout[0].text = response.data.data[0].text
      }
    })
    axios.get(variables.API_URL + '/api/review').then((response) => {

      if (typeof response.data.data !== 'undefined') {
        response.data.data.forEach((item) => {
          this.cardsReviews.push(item)
        })
      }
    })

    axios.get(variables.API_URL + '/api/service').then((response) => {

      if (typeof response.data.data !== 'undefined') {
        response.data.data.forEach((item) => {
          this.cardsService.push(item)
        })
      }
    })

    axios.get(variables.API_URL + '/api/portfolio?limit=4').then((response) => {

      if (typeof response.data.data !== 'undefined') {
        response.data.data.forEach((item) => {
          this.portfolioItems.push(item)
        })
      }
    })
  },
  methods: {
    prevPartnersSlider() {
      this.$refs.PartnersSlider.prev()
    },
    nextPartnersSlider() {
      this.$refs.PartnersSlider.next()
    }
  }
};
</script>

<style scoped>

</style>
