<template>
  <div class="trust-project__cards container-cards">
    <div class="card" v-for="item in items" v-bind:key="item">
      <div class="card__icon">
        <img alt="max-advantages" :src=" require('../../assets/images/icons/' + item.image) " title="Иконка">
      </div>
      <div class="card__title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardAdvantage",
  props: {
    cardsData: Object
  },
  data() {
    return {
      items: this.cardsData
    }
  }
}
</script>

<style scoped>

</style>