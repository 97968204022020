<template>
  <div class="reviews__container">
    <div class="reviews__item" v-for="item in items" v-bind:key="item">
      <div class="item__user">
        <img class="item__user--image" alt="Изобр." :src="item.image" title="Пользователь">
        <div class="user-data-block">
          <div class="user-name">{{ item.name }}</div>
          <div class="user-job">{{ item.jobTitle }}</div>
        </div>
      </div>
      <div class="reviews__stars">
        <svg v-if="item.reviewStars === 0" width="120" height="15" viewBox="0 0 120 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M80.8646 14.3496C80.7866 14.7935 81.2246 15.1405 81.6106 14.9425L86.0005 12.6868L90.3894 14.9425C90.7754 15.1405 91.2134 14.7935 91.1354 14.3506L90.3054 9.62109L93.8274 6.26546C94.1574 5.95149 93.9874 5.37756 93.5454 5.31556L88.6475 4.61964L86.4635 0.293112C86.4219 0.20541 86.3563 0.131312 86.2742 0.0794275C86.1922 0.0275425 86.0971 0 86 0C85.9029 0 85.8078 0.0275425 85.7258 0.0794275C85.6438 0.131312 85.5781 0.20541 85.5365 0.293112L83.3526 4.62064L78.4546 5.31656C78.0137 5.37856 77.8427 5.95249 78.1717 6.26646L81.6946 9.62209L80.8646 14.3516V14.3496ZM85.7695 11.5829L82.0836 13.4767L82.7776 9.5201C82.7938 9.42902 82.7875 9.33535 82.759 9.2473C82.7306 9.15926 82.681 9.07954 82.6146 9.01516L79.7086 6.24546L83.7605 5.66952C83.8445 5.65685 83.924 5.62404 83.9925 5.57389C84.0609 5.52373 84.1162 5.45774 84.1535 5.38156L85.9985 1.72396L87.8455 5.38156C87.8828 5.45774 87.9381 5.52373 88.0065 5.57389C88.075 5.62404 88.1546 5.65685 88.2385 5.66952L92.2904 6.24446L89.3844 9.01416C89.3178 9.07865 89.2681 9.15853 89.2397 9.24676C89.2113 9.335 89.205 9.42887 89.2214 9.5201L89.9154 13.4767L86.2295 11.5829C86.1582 11.5461 86.0792 11.527 85.999 11.527C85.9188 11.527 85.8398 11.5461 85.7685 11.5829H85.7695Z" fill="#E8DA93"/>
          <path d="M54.8646 14.3496C54.7866 14.7935 55.2246 15.1405 55.6106 14.9425L60.0005 12.6868L64.3894 14.9425C64.7754 15.1405 65.2134 14.7935 65.1354 14.3506L64.3054 9.62109L67.8274 6.26546C68.1574 5.95149 67.9874 5.37756 67.5454 5.31556L62.6475 4.61964L60.4635 0.293112C60.4219 0.20541 60.3563 0.131312 60.2742 0.0794275C60.1922 0.0275425 60.0971 0 60 0C59.9029 0 59.8078 0.0275425 59.7258 0.0794275C59.6438 0.131312 59.5781 0.20541 59.5365 0.293112L57.3526 4.62064L52.4546 5.31656C52.0137 5.37856 51.8427 5.95249 52.1717 6.26646L55.6946 9.62209L54.8646 14.3516V14.3496ZM59.7695 11.5829L56.0836 13.4767L56.7776 9.5201C56.7938 9.42902 56.7875 9.33535 56.759 9.2473C56.7306 9.15926 56.681 9.07954 56.6146 9.01516L53.7086 6.24546L57.7605 5.66952C57.8445 5.65685 57.924 5.62404 57.9925 5.57389C58.0609 5.52373 58.1162 5.45774 58.1535 5.38156L59.9985 1.72396L61.8455 5.38156C61.8828 5.45774 61.9381 5.52373 62.0065 5.57389C62.075 5.62404 62.1546 5.65685 62.2385 5.66952L66.2904 6.24446L63.3844 9.01416C63.3178 9.07865 63.2681 9.15853 63.2397 9.24676C63.2113 9.335 63.205 9.42887 63.2214 9.5201L63.9154 13.4767L60.2295 11.5829C60.1582 11.5461 60.0792 11.527 59.999 11.527C59.9188 11.527 59.8398 11.5461 59.7685 11.5829H59.7695Z" fill="#E8DA93"/>
          <path d="M28.8646 14.3496C28.7866 14.7935 29.2246 15.1405 29.6106 14.9425L34.0005 12.6868L38.3894 14.9425C38.7754 15.1405 39.2134 14.7935 39.1354 14.3506L38.3054 9.62109L41.8274 6.26546C42.1574 5.95149 41.9874 5.37756 41.5454 5.31556L36.6475 4.61964L34.4635 0.293112C34.4219 0.20541 34.3563 0.131312 34.2742 0.0794275C34.1922 0.0275425 34.0971 0 34 0C33.9029 0 33.8078 0.0275425 33.7258 0.0794275C33.6438 0.131312 33.5781 0.20541 33.5365 0.293112L31.3526 4.62064L26.4546 5.31656C26.0137 5.37856 25.8427 5.95249 26.1717 6.26646L29.6946 9.62209L28.8646 14.3516V14.3496ZM33.7695 11.5829L30.0836 13.4767L30.7776 9.5201C30.7938 9.42902 30.7875 9.33535 30.759 9.2473C30.7306 9.15926 30.681 9.07954 30.6146 9.01516L27.7086 6.24546L31.7605 5.66952C31.8445 5.65685 31.924 5.62404 31.9925 5.57389C32.0609 5.52373 32.1162 5.45774 32.1535 5.38156L33.9985 1.72396L35.8455 5.38156C35.8828 5.45774 35.9381 5.52373 36.0065 5.57389C36.075 5.62404 36.1546 5.65685 36.2385 5.66952L40.2904 6.24446L37.3844 9.01416C37.3178 9.07865 37.2681 9.15853 37.2397 9.24676C37.2113 9.335 37.205 9.42887 37.2214 9.5201L37.9154 13.4767L34.2295 11.5829C34.1582 11.5461 34.0792 11.527 33.999 11.527C33.9188 11.527 33.8398 11.5461 33.7685 11.5829H33.7695Z" fill="#E8DA93"/>
          <path d="M2.8646 14.3496C2.7866 14.7935 3.22459 15.1405 3.61059 14.9425L8.00051 12.6868L12.3894 14.9425C12.7754 15.1405 13.2134 14.7935 13.1354 14.3506L12.3054 9.62109L15.8274 6.26546C16.1574 5.95149 15.9874 5.37756 15.5454 5.31556L10.6475 4.61964L8.4635 0.293112C8.4219 0.20541 8.35626 0.131312 8.27421 0.0794275C8.19217 0.0275425 8.09708 0 8.00001 0C7.90293 0 7.80785 0.0275425 7.7258 0.0794275C7.64376 0.131312 7.57812 0.20541 7.53652 0.293112L5.35256 4.62064L0.454645 5.31656C0.0136532 5.37856 -0.157344 5.95249 0.17165 6.26646L3.69459 9.62209L2.8646 14.3516V14.3496ZM7.76951 11.5829L4.08358 13.4767L4.77757 9.5201C4.79382 9.42902 4.78746 9.33535 4.75904 9.2473C4.73062 9.15926 4.68101 9.07954 4.61457 9.01516L1.70862 6.24546L5.76055 5.66952C5.84445 5.65685 5.92403 5.62404 5.99248 5.57389C6.06092 5.52373 6.11619 5.45774 6.15354 5.38156L7.99851 1.72396L9.84547 5.38156C9.88283 5.45774 9.93809 5.52373 10.0065 5.57389C10.075 5.62404 10.1546 5.65685 10.2385 5.66952L14.2904 6.24446L11.3844 9.01416C11.3178 9.07865 11.2681 9.15853 11.2397 9.24676C11.2113 9.335 11.205 9.42887 11.2214 9.5201L11.9154 13.4767L8.2295 11.5829C8.15823 11.5461 8.0792 11.527 7.99901 11.527C7.91882 11.527 7.83979 11.5461 7.76851 11.5829H7.76951Z" fill="#E8DA93"/>
          <path d="M106.865 14.3496C106.787 14.7935 107.225 15.1405 107.611 14.9425L112.001 12.6868L116.389 14.9425C116.775 15.1405 117.213 14.7935 117.135 14.3506L116.305 9.62109L119.827 6.26546C120.157 5.95149 119.987 5.37756 119.545 5.31556L114.647 4.61964L112.463 0.293112C112.422 0.20541 112.356 0.131312 112.274 0.0794275C112.192 0.0275425 112.097 0 112 0C111.903 0 111.808 0.0275425 111.726 0.0794275C111.644 0.131312 111.578 0.20541 111.537 0.293112L109.353 4.62064L104.455 5.31656C104.014 5.37856 103.843 5.95249 104.172 6.26646L107.695 9.62209L106.865 14.3516V14.3496ZM111.77 11.5829L108.084 13.4767L108.778 9.5201C108.794 9.42902 108.787 9.33535 108.759 9.2473C108.731 9.15926 108.681 9.07954 108.615 9.01516L105.709 6.24546L109.761 5.66952C109.844 5.65685 109.924 5.62404 109.992 5.57389C110.061 5.52373 110.116 5.45774 110.154 5.38156L111.999 1.72396L113.845 5.38156C113.883 5.45774 113.938 5.52373 114.007 5.57389C114.075 5.62404 114.155 5.65685 114.238 5.66952L118.29 6.24446L115.384 9.01416C115.318 9.07865 115.268 9.15853 115.24 9.24676C115.211 9.335 115.205 9.42887 115.221 9.5201L115.915 13.4767L112.23 11.5829C112.158 11.5461 112.079 11.527 111.999 11.527C111.919 11.527 111.84 11.5461 111.769 11.5829H111.77Z" fill="#E8DA93"/>
        </svg>
        <svg v-if="item.reviewStars === 1" width="120" height="15" viewBox="0 0 120 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.61065 14.943C3.22465 15.141 2.78665 14.794 2.86465 14.351L3.69465 9.62118L0.171653 6.26529C-0.157347 5.9513 0.0136534 5.37732 0.454653 5.31532L5.35265 4.61935L7.53665 0.29249C7.73365 -0.0974968 8.26665 -0.0974968 8.46365 0.29249L10.6477 4.61935L15.5457 5.31532C15.9867 5.37732 16.1577 5.9513 15.8277 6.26529L12.3057 9.62118L13.1357 14.351C13.2137 14.794 12.7757 15.141 12.3897 14.943L7.99865 12.6871L3.60965 14.943H3.61065Z" fill="#E8DA93"/>
          <path d="M80.8646 14.3496C80.7866 14.7935 81.2246 15.1405 81.6106 14.9425L86.0005 12.6868L90.3894 14.9425C90.7754 15.1405 91.2134 14.7935 91.1354 14.3506L90.3054 9.62109L93.8274 6.26546C94.1574 5.95149 93.9874 5.37756 93.5454 5.31556L88.6475 4.61964L86.4635 0.293112C86.4219 0.20541 86.3563 0.131312 86.2742 0.0794275C86.1922 0.0275425 86.0971 0 86 0C85.9029 0 85.8078 0.0275425 85.7258 0.0794275C85.6438 0.131312 85.5781 0.20541 85.5365 0.293112L83.3526 4.62064L78.4546 5.31656C78.0137 5.37856 77.8427 5.95249 78.1717 6.26646L81.6946 9.62209L80.8646 14.3516V14.3496ZM85.7695 11.5829L82.0836 13.4767L82.7776 9.5201C82.7938 9.42902 82.7875 9.33535 82.759 9.2473C82.7306 9.15926 82.681 9.07954 82.6146 9.01516L79.7086 6.24546L83.7605 5.66952C83.8445 5.65685 83.924 5.62404 83.9925 5.57389C84.0609 5.52373 84.1162 5.45774 84.1535 5.38156L85.9985 1.72396L87.8455 5.38156C87.8828 5.45774 87.9381 5.52373 88.0065 5.57389C88.075 5.62404 88.1546 5.65685 88.2385 5.66952L92.2904 6.24446L89.3844 9.01416C89.3178 9.07865 89.2681 9.15853 89.2397 9.24676C89.2113 9.335 89.205 9.42887 89.2214 9.5201L89.9154 13.4767L86.2295 11.5829C86.1582 11.5461 86.0792 11.527 85.999 11.527C85.9188 11.527 85.8398 11.5461 85.7685 11.5829H85.7695Z" fill="#E8DA93"/>
          <path d="M54.8646 14.3496C54.7866 14.7935 55.2246 15.1405 55.6106 14.9425L60.0005 12.6868L64.3894 14.9425C64.7754 15.1405 65.2134 14.7935 65.1354 14.3506L64.3054 9.62109L67.8274 6.26546C68.1574 5.95149 67.9874 5.37756 67.5454 5.31556L62.6475 4.61964L60.4635 0.293112C60.4219 0.20541 60.3563 0.131312 60.2742 0.0794275C60.1922 0.0275425 60.0971 0 60 0C59.9029 0 59.8078 0.0275425 59.7258 0.0794275C59.6438 0.131312 59.5781 0.20541 59.5365 0.293112L57.3526 4.62064L52.4546 5.31656C52.0137 5.37856 51.8427 5.95249 52.1717 6.26646L55.6946 9.62209L54.8646 14.3516V14.3496ZM59.7695 11.5829L56.0836 13.4767L56.7776 9.5201C56.7938 9.42902 56.7875 9.33535 56.759 9.2473C56.7306 9.15926 56.681 9.07954 56.6146 9.01516L53.7086 6.24546L57.7605 5.66952C57.8445 5.65685 57.924 5.62404 57.9925 5.57389C58.0609 5.52373 58.1162 5.45774 58.1535 5.38156L59.9985 1.72396L61.8455 5.38156C61.8828 5.45774 61.9381 5.52373 62.0065 5.57389C62.075 5.62404 62.1546 5.65685 62.2385 5.66952L66.2904 6.24446L63.3844 9.01416C63.3178 9.07865 63.2681 9.15853 63.2397 9.24676C63.2113 9.335 63.205 9.42887 63.2214 9.5201L63.9154 13.4767L60.2295 11.5829C60.1582 11.5461 60.0792 11.527 59.999 11.527C59.9188 11.527 59.8398 11.5461 59.7685 11.5829H59.7695Z" fill="#E8DA93"/>
          <path d="M28.8646 14.3496C28.7866 14.7935 29.2246 15.1405 29.6106 14.9425L34.0005 12.6868L38.3894 14.9425C38.7754 15.1405 39.2134 14.7935 39.1354 14.3506L38.3054 9.62109L41.8274 6.26546C42.1574 5.95149 41.9874 5.37756 41.5454 5.31556L36.6475 4.61964L34.4635 0.293112C34.4219 0.20541 34.3563 0.131312 34.2742 0.0794275C34.1922 0.0275425 34.0971 0 34 0C33.9029 0 33.8078 0.0275425 33.7258 0.0794275C33.6438 0.131312 33.5781 0.20541 33.5365 0.293112L31.3526 4.62064L26.4546 5.31656C26.0137 5.37856 25.8427 5.95249 26.1717 6.26646L29.6946 9.62209L28.8646 14.3516V14.3496ZM33.7695 11.5829L30.0836 13.4767L30.7776 9.5201C30.7938 9.42902 30.7875 9.33535 30.759 9.2473C30.7306 9.15926 30.681 9.07954 30.6146 9.01516L27.7086 6.24546L31.7605 5.66952C31.8445 5.65685 31.924 5.62404 31.9925 5.57389C32.0609 5.52373 32.1162 5.45774 32.1535 5.38156L33.9985 1.72396L35.8455 5.38156C35.8828 5.45774 35.9381 5.52373 36.0065 5.57389C36.075 5.62404 36.1546 5.65685 36.2385 5.66952L40.2904 6.24446L37.3844 9.01416C37.3178 9.07865 37.2681 9.15853 37.2397 9.24676C37.2113 9.335 37.205 9.42887 37.2214 9.5201L37.9154 13.4767L34.2295 11.5829C34.1582 11.5461 34.0792 11.527 33.999 11.527C33.9188 11.527 33.8398 11.5461 33.7685 11.5829H33.7695Z" fill="#E8DA93"/>
          <path d="M106.865 14.3496C106.787 14.7935 107.225 15.1405 107.611 14.9425L112.001 12.6868L116.389 14.9425C116.775 15.1405 117.213 14.7935 117.135 14.3506L116.305 9.62109L119.827 6.26546C120.157 5.95149 119.987 5.37756 119.545 5.31556L114.647 4.61964L112.463 0.293112C112.422 0.20541 112.356 0.131312 112.274 0.0794275C112.192 0.0275425 112.097 0 112 0C111.903 0 111.808 0.0275425 111.726 0.0794275C111.644 0.131312 111.578 0.20541 111.537 0.293112L109.353 4.62064L104.455 5.31656C104.014 5.37856 103.843 5.95249 104.172 6.26646L107.695 9.62209L106.865 14.3516V14.3496ZM111.77 11.5829L108.084 13.4767L108.778 9.5201C108.794 9.42902 108.787 9.33535 108.759 9.2473C108.731 9.15926 108.681 9.07954 108.615 9.01516L105.709 6.24546L109.761 5.66952C109.844 5.65685 109.924 5.62404 109.992 5.57389C110.061 5.52373 110.116 5.45774 110.154 5.38156L111.999 1.72396L113.845 5.38156C113.883 5.45774 113.938 5.52373 114.007 5.57389C114.075 5.62404 114.155 5.65685 114.238 5.66952L118.29 6.24446L115.384 9.01416C115.318 9.07865 115.268 9.15853 115.24 9.24676C115.211 9.335 115.205 9.42887 115.221 9.5201L115.915 13.4767L112.23 11.5829C112.158 11.5461 112.079 11.527 111.999 11.527C111.919 11.527 111.84 11.5461 111.769 11.5829H111.77Z" fill="#E8DA93"/>
        </svg>
        <svg v-if="item.reviewStars === 2" width="120" height="15" viewBox="0 0 120 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.61065 14.943C3.22465 15.141 2.78665 14.794 2.86465 14.351L3.69465 9.62118L0.171653 6.26529C-0.157347 5.9513 0.0136534 5.37732 0.454653 5.31532L5.35265 4.61935L7.53665 0.29249C7.73365 -0.0974968 8.26665 -0.0974968 8.46365 0.29249L10.6477 4.61935L15.5457 5.31532C15.9867 5.37732 16.1577 5.9513 15.8277 6.26529L12.3057 9.62118L13.1357 14.351C13.2137 14.794 12.7757 15.141 12.3897 14.943L7.99865 12.6871L3.60965 14.943H3.61065Z" fill="#E8DA93"/>
          <path d="M29.6107 14.943C29.2247 15.141 28.7867 14.794 28.8647 14.351L29.6947 9.62118L26.1717 6.26529C25.8427 5.9513 26.0137 5.37732 26.4547 5.31532L31.3527 4.61935L33.5367 0.29249C33.7337 -0.0974968 34.2667 -0.0974968 34.4637 0.29249L36.6477 4.61935L41.5457 5.31532C41.9867 5.37732 42.1577 5.9513 41.8277 6.26529L38.3057 9.62118L39.1357 14.351C39.2137 14.794 38.7757 15.141 38.3897 14.943L33.9987 12.6871L29.6097 14.943H29.6107Z" fill="#E8DA93"/>
          <path d="M80.8646 14.3496C80.7866 14.7935 81.2246 15.1405 81.6106 14.9425L86.0005 12.6868L90.3894 14.9425C90.7754 15.1405 91.2134 14.7935 91.1354 14.3506L90.3054 9.62109L93.8274 6.26546C94.1574 5.95149 93.9874 5.37756 93.5454 5.31556L88.6475 4.61964L86.4635 0.293112C86.4219 0.20541 86.3563 0.131312 86.2742 0.0794275C86.1922 0.0275425 86.0971 0 86 0C85.9029 0 85.8078 0.0275425 85.7258 0.0794275C85.6438 0.131312 85.5781 0.20541 85.5365 0.293112L83.3526 4.62064L78.4546 5.31656C78.0137 5.37856 77.8427 5.95249 78.1717 6.26646L81.6946 9.62209L80.8646 14.3516V14.3496ZM85.7695 11.5829L82.0836 13.4767L82.7776 9.5201C82.7938 9.42902 82.7875 9.33535 82.759 9.2473C82.7306 9.15926 82.681 9.07954 82.6146 9.01516L79.7086 6.24546L83.7605 5.66952C83.8445 5.65685 83.924 5.62404 83.9925 5.57389C84.0609 5.52373 84.1162 5.45774 84.1535 5.38156L85.9985 1.72396L87.8455 5.38156C87.8828 5.45774 87.9381 5.52373 88.0065 5.57389C88.075 5.62404 88.1546 5.65685 88.2385 5.66952L92.2904 6.24446L89.3844 9.01416C89.3178 9.07865 89.2681 9.15853 89.2397 9.24676C89.2113 9.335 89.205 9.42887 89.2214 9.5201L89.9154 13.4767L86.2295 11.5829C86.1582 11.5461 86.0792 11.527 85.999 11.527C85.9188 11.527 85.8398 11.5461 85.7685 11.5829H85.7695Z" fill="#E8DA93"/>
          <path d="M54.8646 14.3496C54.7866 14.7935 55.2246 15.1405 55.6106 14.9425L60.0005 12.6868L64.3894 14.9425C64.7754 15.1405 65.2134 14.7935 65.1354 14.3506L64.3054 9.62109L67.8274 6.26546C68.1574 5.95149 67.9874 5.37756 67.5454 5.31556L62.6475 4.61964L60.4635 0.293112C60.4219 0.20541 60.3563 0.131312 60.2742 0.0794275C60.1922 0.0275425 60.0971 0 60 0C59.9029 0 59.8078 0.0275425 59.7258 0.0794275C59.6438 0.131312 59.5781 0.20541 59.5365 0.293112L57.3526 4.62064L52.4546 5.31656C52.0137 5.37856 51.8427 5.95249 52.1717 6.26646L55.6946 9.62209L54.8646 14.3516V14.3496ZM59.7695 11.5829L56.0836 13.4767L56.7776 9.5201C56.7938 9.42902 56.7875 9.33535 56.759 9.2473C56.7306 9.15926 56.681 9.07954 56.6146 9.01516L53.7086 6.24546L57.7605 5.66952C57.8445 5.65685 57.924 5.62404 57.9925 5.57389C58.0609 5.52373 58.1162 5.45774 58.1535 5.38156L59.9985 1.72396L61.8455 5.38156C61.8828 5.45774 61.9381 5.52373 62.0065 5.57389C62.075 5.62404 62.1546 5.65685 62.2385 5.66952L66.2904 6.24446L63.3844 9.01416C63.3178 9.07865 63.2681 9.15853 63.2397 9.24676C63.2113 9.335 63.205 9.42887 63.2214 9.5201L63.9154 13.4767L60.2295 11.5829C60.1582 11.5461 60.0792 11.527 59.999 11.527C59.9188 11.527 59.8398 11.5461 59.7685 11.5829H59.7695Z" fill="#E8DA93"/>
          <path d="M106.865 14.3496C106.787 14.7935 107.225 15.1405 107.611 14.9425L112.001 12.6868L116.389 14.9425C116.775 15.1405 117.213 14.7935 117.135 14.3506L116.305 9.62109L119.827 6.26546C120.157 5.95149 119.987 5.37756 119.545 5.31556L114.647 4.61964L112.463 0.293112C112.422 0.20541 112.356 0.131312 112.274 0.0794275C112.192 0.0275425 112.097 0 112 0C111.903 0 111.808 0.0275425 111.726 0.0794275C111.644 0.131312 111.578 0.20541 111.537 0.293112L109.353 4.62064L104.455 5.31656C104.014 5.37856 103.843 5.95249 104.172 6.26646L107.695 9.62209L106.865 14.3516V14.3496ZM111.77 11.5829L108.084 13.4767L108.778 9.5201C108.794 9.42902 108.787 9.33535 108.759 9.2473C108.731 9.15926 108.681 9.07954 108.615 9.01516L105.709 6.24546L109.761 5.66952C109.844 5.65685 109.924 5.62404 109.992 5.57389C110.061 5.52373 110.116 5.45774 110.154 5.38156L111.999 1.72396L113.845 5.38156C113.883 5.45774 113.938 5.52373 114.007 5.57389C114.075 5.62404 114.155 5.65685 114.238 5.66952L118.29 6.24446L115.384 9.01416C115.318 9.07865 115.268 9.15853 115.24 9.24676C115.211 9.335 115.205 9.42887 115.221 9.5201L115.915 13.4767L112.23 11.5829C112.158 11.5461 112.079 11.527 111.999 11.527C111.919 11.527 111.84 11.5461 111.769 11.5829H111.77Z" fill="#E8DA93"/>

        </svg>
        <svg v-if="item.reviewStars === 3" width="120" height="15" viewBox="0 0 120 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.61065 14.943C3.22465 15.141 2.78665 14.794 2.86465 14.351L3.69465 9.62118L0.171653 6.26529C-0.157347 5.9513 0.0136534 5.37732 0.454653 5.31532L5.35265 4.61935L7.53665 0.29249C7.73365 -0.0974968 8.26665 -0.0974968 8.46365 0.29249L10.6477 4.61935L15.5457 5.31532C15.9867 5.37732 16.1577 5.9513 15.8277 6.26529L12.3057 9.62118L13.1357 14.351C13.2137 14.794 12.7757 15.141 12.3897 14.943L7.99865 12.6871L3.60965 14.943H3.61065Z" fill="#E8DA93"/>
          <path d="M29.6107 14.943C29.2247 15.141 28.7867 14.794 28.8647 14.351L29.6947 9.62118L26.1717 6.26529C25.8427 5.9513 26.0137 5.37732 26.4547 5.31532L31.3527 4.61935L33.5367 0.29249C33.7337 -0.0974968 34.2667 -0.0974968 34.4637 0.29249L36.6477 4.61935L41.5457 5.31532C41.9867 5.37732 42.1577 5.9513 41.8277 6.26529L38.3057 9.62118L39.1357 14.351C39.2137 14.794 38.7757 15.141 38.3897 14.943L33.9987 12.6871L29.6097 14.943H29.6107Z" fill="#E8DA93"/>
          <path d="M55.6107 14.943C55.2247 15.141 54.7867 14.794 54.8647 14.351L55.6947 9.62118L52.1717 6.26529C51.8427 5.9513 52.0137 5.37732 52.4547 5.31532L57.3527 4.61935L59.5367 0.29249C59.7337 -0.0974968 60.2667 -0.0974968 60.4637 0.29249L62.6477 4.61935L67.5457 5.31532C67.9867 5.37732 68.1577 5.9513 67.8277 6.26529L64.3057 9.62118L65.1357 14.351C65.2137 14.794 64.7757 15.141 64.3897 14.943L59.9987 12.6871L55.6097 14.943H55.6107Z" fill="#E8DA93"/>
          <path d="M80.8646 14.3496C80.7866 14.7935 81.2246 15.1405 81.6106 14.9425L86.0005 12.6868L90.3894 14.9425C90.7754 15.1405 91.2134 14.7935 91.1354 14.3506L90.3054 9.62109L93.8274 6.26546C94.1574 5.95149 93.9874 5.37756 93.5454 5.31556L88.6475 4.61964L86.4635 0.293112C86.4219 0.20541 86.3563 0.131312 86.2742 0.0794275C86.1922 0.0275425 86.0971 0 86 0C85.9029 0 85.8078 0.0275425 85.7258 0.0794275C85.6438 0.131312 85.5781 0.20541 85.5365 0.293112L83.3526 4.62064L78.4546 5.31656C78.0137 5.37856 77.8427 5.95249 78.1717 6.26646L81.6946 9.62209L80.8646 14.3516V14.3496ZM85.7695 11.5829L82.0836 13.4767L82.7776 9.5201C82.7938 9.42902 82.7875 9.33535 82.759 9.2473C82.7306 9.15926 82.681 9.07954 82.6146 9.01516L79.7086 6.24546L83.7605 5.66952C83.8445 5.65685 83.924 5.62404 83.9925 5.57389C84.0609 5.52373 84.1162 5.45774 84.1535 5.38156L85.9985 1.72396L87.8455 5.38156C87.8828 5.45774 87.9381 5.52373 88.0065 5.57389C88.075 5.62404 88.1546 5.65685 88.2385 5.66952L92.2904 6.24446L89.3844 9.01416C89.3178 9.07865 89.2681 9.15853 89.2397 9.24676C89.2113 9.335 89.205 9.42887 89.2214 9.5201L89.9154 13.4767L86.2295 11.5829C86.1582 11.5461 86.0792 11.527 85.999 11.527C85.9188 11.527 85.8398 11.5461 85.7685 11.5829H85.7695Z" fill="#E8DA93"/>
          <path d="M106.865 14.3496C106.787 14.7935 107.225 15.1405 107.611 14.9425L112.001 12.6868L116.389 14.9425C116.775 15.1405 117.213 14.7935 117.135 14.3506L116.305 9.62109L119.827 6.26546C120.157 5.95149 119.987 5.37756 119.545 5.31556L114.647 4.61964L112.463 0.293112C112.422 0.20541 112.356 0.131312 112.274 0.0794275C112.192 0.0275425 112.097 0 112 0C111.903 0 111.808 0.0275425 111.726 0.0794275C111.644 0.131312 111.578 0.20541 111.537 0.293112L109.353 4.62064L104.455 5.31656C104.014 5.37856 103.843 5.95249 104.172 6.26646L107.695 9.62209L106.865 14.3516V14.3496ZM111.77 11.5829L108.084 13.4767L108.778 9.5201C108.794 9.42902 108.787 9.33535 108.759 9.2473C108.731 9.15926 108.681 9.07954 108.615 9.01516L105.709 6.24546L109.761 5.66952C109.844 5.65685 109.924 5.62404 109.992 5.57389C110.061 5.52373 110.116 5.45774 110.154 5.38156L111.999 1.72396L113.845 5.38156C113.883 5.45774 113.938 5.52373 114.007 5.57389C114.075 5.62404 114.155 5.65685 114.238 5.66952L118.29 6.24446L115.384 9.01416C115.318 9.07865 115.268 9.15853 115.24 9.24676C115.211 9.335 115.205 9.42887 115.221 9.5201L115.915 13.4767L112.23 11.5829C112.158 11.5461 112.079 11.527 111.999 11.527C111.919 11.527 111.84 11.5461 111.769 11.5829H111.77Z" fill="#E8DA93"/>

        </svg>
        <svg v-if="item.reviewStars === 4" width="120" height="15" viewBox="0 0 120 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.61065 14.943C3.22465 15.141 2.78665 14.794 2.86465 14.351L3.69465 9.62118L0.171653 6.26529C-0.157347 5.9513 0.0136534 5.37732 0.454653 5.31532L5.35265 4.61935L7.53665 0.29249C7.73365 -0.0974968 8.26665 -0.0974968 8.46365 0.29249L10.6477 4.61935L15.5457 5.31532C15.9867 5.37732 16.1577 5.9513 15.8277 6.26529L12.3057 9.62118L13.1357 14.351C13.2137 14.794 12.7757 15.141 12.3897 14.943L7.99865 12.6871L3.60965 14.943H3.61065Z" fill="#E8DA93"/>
          <path d="M29.6107 14.943C29.2247 15.141 28.7867 14.794 28.8647 14.351L29.6947 9.62118L26.1717 6.26529C25.8427 5.9513 26.0137 5.37732 26.4547 5.31532L31.3527 4.61935L33.5367 0.29249C33.7337 -0.0974968 34.2667 -0.0974968 34.4637 0.29249L36.6477 4.61935L41.5457 5.31532C41.9867 5.37732 42.1577 5.9513 41.8277 6.26529L38.3057 9.62118L39.1357 14.351C39.2137 14.794 38.7757 15.141 38.3897 14.943L33.9987 12.6871L29.6097 14.943H29.6107Z" fill="#E8DA93"/>
          <path d="M55.6107 14.943C55.2247 15.141 54.7867 14.794 54.8647 14.351L55.6947 9.62118L52.1717 6.26529C51.8427 5.9513 52.0137 5.37732 52.4547 5.31532L57.3527 4.61935L59.5367 0.29249C59.7337 -0.0974968 60.2667 -0.0974968 60.4637 0.29249L62.6477 4.61935L67.5457 5.31532C67.9867 5.37732 68.1577 5.9513 67.8277 6.26529L64.3057 9.62118L65.1357 14.351C65.2137 14.794 64.7757 15.141 64.3897 14.943L59.9987 12.6871L55.6097 14.943H55.6107Z" fill="#E8DA93"/>
          <path d="M81.6107 14.943C81.2247 15.141 80.7867 14.794 80.8647 14.351L81.6947 9.62118L78.1717 6.26529C77.8427 5.9513 78.0137 5.37732 78.4547 5.31532L83.3527 4.61935L85.5367 0.29249C85.7337 -0.0974968 86.2667 -0.0974968 86.4637 0.29249L88.6477 4.61935L93.5457 5.31532C93.9867 5.37732 94.1577 5.9513 93.8277 6.26529L90.3057 9.62118L91.1357 14.351C91.2137 14.794 90.7757 15.141 90.3897 14.943L85.9987 12.6871L81.6097 14.943H81.6107Z" fill="#E8DA93"/>
          <path d="M106.865 14.3496C106.787 14.7935 107.225 15.1405 107.611 14.9425L112.001 12.6868L116.389 14.9425C116.775 15.1405 117.213 14.7935 117.135 14.3506L116.305 9.62109L119.827 6.26546C120.157 5.95149 119.987 5.37756 119.545 5.31556L114.647 4.61964L112.463 0.293112C112.422 0.20541 112.356 0.131312 112.274 0.0794275C112.192 0.0275425 112.097 0 112 0C111.903 0 111.808 0.0275425 111.726 0.0794275C111.644 0.131312 111.578 0.20541 111.537 0.293112L109.353 4.62064L104.455 5.31656C104.014 5.37856 103.843 5.95249 104.172 6.26646L107.695 9.62209L106.865 14.3516V14.3496ZM111.77 11.5829L108.084 13.4767L108.778 9.5201C108.794 9.42902 108.787 9.33535 108.759 9.2473C108.731 9.15926 108.681 9.07954 108.615 9.01516L105.709 6.24546L109.761 5.66952C109.844 5.65685 109.924 5.62404 109.992 5.57389C110.061 5.52373 110.116 5.45774 110.154 5.38156L111.999 1.72396L113.845 5.38156C113.883 5.45774 113.938 5.52373 114.007 5.57389C114.075 5.62404 114.155 5.65685 114.238 5.66952L118.29 6.24446L115.384 9.01416C115.318 9.07865 115.268 9.15853 115.24 9.24676C115.211 9.335 115.205 9.42887 115.221 9.5201L115.915 13.4767L112.23 11.5829C112.158 11.5461 112.079 11.527 111.999 11.527C111.919 11.527 111.84 11.5461 111.769 11.5829H111.77Z" fill="#E8DA93"/>

        </svg>
        <svg v-if="item.reviewStars === 5" width="120" height="15" viewBox="0 0 120 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.61065 14.943C3.22465 15.141 2.78665 14.794 2.86465 14.351L3.69465 9.62118L0.171653 6.26529C-0.157347 5.9513 0.0136534 5.37732 0.454653 5.31532L5.35265 4.61935L7.53665 0.29249C7.73365 -0.0974968 8.26665 -0.0974968 8.46365 0.29249L10.6477 4.61935L15.5457 5.31532C15.9867 5.37732 16.1577 5.9513 15.8277 6.26529L12.3057 9.62118L13.1357 14.351C13.2137 14.794 12.7757 15.141 12.3897 14.943L7.99865 12.6871L3.60965 14.943H3.61065Z" fill="#E8DA93"/>
          <path d="M29.6107 14.943C29.2247 15.141 28.7867 14.794 28.8647 14.351L29.6947 9.62118L26.1717 6.26529C25.8427 5.9513 26.0137 5.37732 26.4547 5.31532L31.3527 4.61935L33.5367 0.29249C33.7337 -0.0974968 34.2667 -0.0974968 34.4637 0.29249L36.6477 4.61935L41.5457 5.31532C41.9867 5.37732 42.1577 5.9513 41.8277 6.26529L38.3057 9.62118L39.1357 14.351C39.2137 14.794 38.7757 15.141 38.3897 14.943L33.9987 12.6871L29.6097 14.943H29.6107Z" fill="#E8DA93"/>
          <path d="M55.6107 14.943C55.2247 15.141 54.7867 14.794 54.8647 14.351L55.6947 9.62118L52.1717 6.26529C51.8427 5.9513 52.0137 5.37732 52.4547 5.31532L57.3527 4.61935L59.5367 0.29249C59.7337 -0.0974968 60.2667 -0.0974968 60.4637 0.29249L62.6477 4.61935L67.5457 5.31532C67.9867 5.37732 68.1577 5.9513 67.8277 6.26529L64.3057 9.62118L65.1357 14.351C65.2137 14.794 64.7757 15.141 64.3897 14.943L59.9987 12.6871L55.6097 14.943H55.6107Z" fill="#E8DA93"/>
          <path d="M81.6107 14.943C81.2247 15.141 80.7867 14.794 80.8647 14.351L81.6947 9.62118L78.1717 6.26529C77.8427 5.9513 78.0137 5.37732 78.4547 5.31532L83.3527 4.61935L85.5367 0.29249C85.7337 -0.0974968 86.2667 -0.0974968 86.4637 0.29249L88.6477 4.61935L93.5457 5.31532C93.9867 5.37732 94.1577 5.9513 93.8277 6.26529L90.3057 9.62118L91.1357 14.351C91.2137 14.794 90.7757 15.141 90.3897 14.943L85.9987 12.6871L81.6097 14.943H81.6107Z" fill="#E8DA93"/>
          <path d="M107.611 14.943C107.225 15.141 106.787 14.794 106.865 14.351L107.695 9.62118L104.172 6.26529C103.843 5.9513 104.014 5.37732 104.455 5.31532L109.353 4.61935L111.537 0.29249C111.734 -0.0974968 112.267 -0.0974968 112.464 0.29249L114.648 4.61935L119.546 5.31532C119.987 5.37732 120.158 5.9513 119.828 6.26529L116.306 9.62118L117.136 14.351C117.214 14.794 116.776 15.141 116.39 14.943L111.999 12.6871L107.61 14.943H107.611Z" fill="#E8DA93"/>

        </svg>
      </div>
      <div class="reviews__text">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardReview",
  props: {
    cardsData: Object
  },
  data() {
    return {
      items: this.cardsData
    }
  }
}
</script>

<style scoped>

</style>