<template>
  <div class="text-image__content" v-for="item in items" v-bind:key="item">
    <div class="text">{{ item.text }}</div>
    <div class="text-image__image image">
      <img alt="text-image_picture" :src=" require('../assets/images/background/' + item.image ) " title="О нас">
    </div>
  </div>
</template>

<script>
export default {
  name: "ElementTextImage",
  props: {
    elementData: Object
  },
  data() {
    return {
      items: this.elementData
    }
  }
}
</script>

<style scoped>

</style>