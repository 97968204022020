<template>
  <div class="trust-project__cards container-cards">
    <div class="card" v-for="item in items" v-bind:key="item">
      <div class="card__icon">
        <img alt="pc" :src=" require('../../assets/images/icons/icon_card-email.svg') " title="Иконка">
      </div>
      <div class="card__title">{{ item.title }}</div>
      <div class="card__text">{{ item.text }}</div>
      <div class="button card__button" v-on:click="activeModal"><span>Заказать</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardService",
  props: {
    cardsData: Object
  },
  data() {
    return {
      items: this.cardsData
    }
  },
  methods: {
    activeModal() {
      document.querySelector('.modal__order-service').classList.toggle('active')
    }
  }
}
</script>

<style scoped>

</style>